import React from 'react';
import cn from 'classnames';
import styles from './index.module.less'
import Icon from '@components/Icon';

export function Refresh ({ callback, refreshing = false }) {
  // const { className } = props;

  const click = () => {
    scrollTo(0, 0)
    callback?.();
  }
  return (
    <div className={cn(styles.imgContainer, {
      [styles.rotate]: refreshing
    })} onClick={click} data-buried='refresh'>
    <Icon name='refreshLine' className={styles.icon} />
    </div>
  )
}
