import { I18n } from '@utils/index';

const tagConfig = (step) => {
  const kyc = {
    1: 'KYC.1 ',
    2: 'KYC.2 '
  };
  return {
    unauthorized: {
      text: kyc[step] + I18n.t('kyc_unverified'), // 这样写有助于I18n扫描,
      type: 'kycUnauthorized',
      iconName: 'kycUnauthorized'
    },
    inReview: {
      text: kyc[step] + I18n.t('jXzzu2lV'),
      type: 'kycInReview',
      iconName: 'kycInReview'
    },
    failed: {
      text: kyc[step] + I18n.t('m9PDlGA9'),
      type: 'kycFailed',
      iconName: 'kycFailed'
    },
    success: {
      text: kyc[step] + I18n.t('kyc_verified'),
      type: 'kycSuccess',
      iconName: 'kycSuccess'
    }
  };
};

export const KYC_STEP1 = () => ({
  0: tagConfig(1).unauthorized,
  1: tagConfig(1).inReview,
  2: tagConfig(1).failed,
  3: tagConfig(1).success,
  4: tagConfig(1).success,
  5: tagConfig(1).success,
  6: tagConfig(1).success
});

export const KYC_STEP2 = () => ({
  0: tagConfig(2).unauthorized,
  1: tagConfig(2).unauthorized,
  2: tagConfig(2).unauthorized,
  3: tagConfig(2).unauthorized,
  4: tagConfig(2).inReview,
  5: tagConfig(2).failed,
  6: tagConfig(2).success
});

export const KYC_STEP = () => ({
  0: tagConfig(1).unauthorized,
  1: tagConfig(1).inReview,
  2: tagConfig(1).failed,
  3: {
    ...tagConfig(1).unauthorized,
    text: 'KYC.2 ' + I18n.t('nvCi8rcf')
  },
  4: tagConfig(2).inReview,
  5: tagConfig(2).failed,
  6: tagConfig(2).success
});
