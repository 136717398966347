import React, { useState } from 'react';
import cn from 'classnames';
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Spin, InfiniteScroll } from '@element/index';
import styles from './index.module.css';

export const ScrollLoading = (props) => {
  const [loading, setLoading] = useState('success');
  const {
    invisibleHeight,
    fetchData,
    className,
    renderLoading,
    renderError,
    renderEmpty,
    status,
    loaddingClass,
    spinClass,
    children
  } = props;
  const loadData = async (unObserver) => {
    try {
      setLoading('loading')
      await fetchData(unObserver);
      setLoading('success')
      return Promise.resolve()
    } catch (error) {
      setLoading('success')
      return Promise.reject(error)
    }
  }
  return (
    <>
      <InfiniteScroll invisibleHeight={invisibleHeight} fetchData={loadData} className={className}>
        {children}
      </InfiniteScroll>
      <Spin
        className={cn(styles.spin, spinClass, {
          [styles.hidden]: (status && status === 'success') || (!status && loading === 'success')
        })}
        loaddingClass={cn(styles.loading, loaddingClass)}
        status={status || loading}
        renderError={renderError}
        renderEmpty={renderEmpty}
        renderLoading={renderLoading || <Loading3QuartersOutlined className={cn('anticon', 'anticon-loading', 'anticon-spin', styles.loadingIcon)} />}
      />
    </>
  )
}
