import React from 'react';

import Link from 'next/link';

import { openModal } from '@element/index';
import I18n from '@utils/i18n';

import styles from './index.module.css';

export const openMargin = ({ callback }) => {
  const hrefEN = 'https://kikitrade.zendesk.com/hc/en-hk/articles/4406220052495';
  const hrefZH = 'https://kikitrade.zendesk.com/hc/zh-tw/articles/4406220052495';
  return new Promise((resolve, reject) => {
    openModal({
      title: I18n.t('tip'),
      cancelText: I18n.t('common_cancel'),
      okText: I18n.t('ok'),
      children: (
        <div className={styles.modalContent}>
          <div className={styles.contentWrapper}>
            <p className={styles.p}>{I18n.t('LeverManageModalTip2')}</p>
            <p className={styles.link}>
              <Link legacyBehavior href={I18n.locale === 'en' ? hrefEN : hrefZH}>
                <a target='_blank'>{I18n.t('marginTipMore')}</a>
              </Link>
            </p>
          </div>
        </div>
      ),
      onCancel: (close) => (e) => {
        close()
        resolve('cancel')
      },
      onOk: (close) => (e) => {
        callback({ close, resolve, reject })
      }
    })
  })
}
