import config from '@utils/config';
import request from '@utils/request';
// import helper from '@utils/helper';
// import DeviceInfo from 'react-native-device-info';

// let jwtKey: ?string;
// subscribe('HomeReducer', (state: StateType) => {
//   const { HomeReducer } = state;
//   jwtKey = HomeReducer.userInfo?.jwtKey ?? '';
// });
let namedSupportUrl;

// class definition
class SupportAPi {
  /**
 * sign the extInfo
 * @param appVersion
 * @param os
 * @param deviceId
 * @param systemVersion
 * @returns {*}
 */
  // sign (appVersion: string, os: string, deviceId: string, systemVersion: string): Promise {
  sign () {
    const URL =
      config.api.host_newapi +
      config.api.support.sign
    // '?' +
    // helper.mapToQueryString({ appVersion, os, deviceId, systemVersion });
    // return request.post.call(this, URL, jwtKey ? { jwtKey } : {});
    return request.post.call(this, URL, {});
  };

  async getNamedSupportUrl () {
    try {
      if (namedSupportUrl) {
        return namedSupportUrl;
      }
      const response = await SupportAPI.sign();
      if (response.obj) {
        namedSupportUrl = config.supportUrl + '&cinfo=' + response.obj.cinfo + '&key=' + response.obj.key;
      } else {
        namedSupportUrl = config.supportUrl;
      }
      namedSupportUrl = config.supportUrl;
      return namedSupportUrl;
    } catch (error) {
      console.log(error);
      // bugsnag.notify(new Error('[SupportPI]--[getNamedSupportUrl]--error  : ' + error.toString()));
      return config.supportUrl;
    }
  }
}

const SupportAPI = new SupportAPi();
export { SupportAPI };
