import React, { useState } from 'react';

import { InputNumber as AntInputNumber, InputNumberProps } from 'antd';
import cn from 'classnames'

import styles from './index.module.less'

export const InputNumber: React.FC<InputNumberProps> = (props) => {
  const { className, ...restProps } = props
  const [isFours, setisFours] = useState(false)
  return (
    <div className={cn(styles.input, className, {
      [styles.fours]: isFours
    })} data-name='kiki-inputContain'>
        <AntInputNumber
        {...restProps}
        onFocus={() => {
          setisFours(true)
        }}
        onBlur={() => {
          setisFours(false)
        }}
      />
    </div>
  )
}
