import React, { useMemo, useRef, useState } from 'react';

import { Button, Collapse, ConfigProvider } from 'antd';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { useOSWalletConnect } from '@/web3/hooks/particle/useOSWalletConnect'
import { isEoaWallet } from '@/web3/utils/particle/isEoaWallet'
import Icon from '@components/Icon';
import { Popover } from '@element/index';
import defaultAvatar from '@images/avata.webp';
import { useLogout } from '@lib/hooks';
import pubsub from '@lib/scripts/pubsub'
import helper from '@utils/helper';
import I18n from '@utils/i18n';

import { PopoverBtnProps } from './index.type';

import styles from './index.module.less';

const TYPE_PROFILE = 'profile';
const TYPE_LANGUAGE = 'language';

const PopoverBtnFn: React.FC<PopoverBtnProps> = ({
  userStore,
  type = 'common', // 'common' | 'profile'
  isJumpLogin,
  primaryText,
  href = '',
  modal = false,
  modalFn,
  hasArrow = false,
  childrenMenu = [],
  btnClassName,
  placement,
  align,
  trigger,
  onVisibleChangePorps = () => {},
  popoverWidth,
  containClassName,
  childrenMenClassname,
  dataName = 'kiki-navabar-web-btn-init',
  childrenMenuItemClassName
}) => {
  // console.log(childrenMenuItemClassName, 'childrenMenuItemClassName')
  const containerRef = useRef();
  const [menuVisible, setMenuVisible] = useState(false);
  const {
    socialUserInfo: { nickName, avatar }
  } = userStore;
  const { logout } = useLogout({ userStore, isJumpLogin });
  const { disconnectWallet } = useOSWalletConnect()
  const onClickLogOut = async () => {
    await logout()
    if (isEoaWallet()) {
      pubsub.publish('DISCONNECT_WALLET')
    } else {
      await disconnectWallet()
    }
  }
  function renderLogout () {
    return (
      <li className={cn(styles.childrenMenuItem, styles.padding20, styles.h60)}>
        <div className={cn(styles.logout, 'fontMedium')} onClick={onClickLogOut}>
          <Icon
            name="exit"
            className={cn(styles.childrenMenuIcon, styles.mr12)}
          />
          {I18n.t('layout_logout')}
        </div>
      </li>
    );
  }

  function renderMenu () {
    const className = cn(styles.childrenMenu, {
      [styles.noPaddingTop]: type === TYPE_PROFILE
    });
    const itemClassName = cn(styles.childrenMenuItem, styles.padding20, {
      [styles.h60]: type === TYPE_PROFILE,
      Medium: type !== TYPE_PROFILE
    });
    const iconClassName = cn(styles.childrenMenuIcon, {
      [styles.mr12]: type === TYPE_PROFILE
    });
    return (
      <ul
        className={cn(className, childrenMenClassname)}
        style={{ width: `${popoverWidth}` }}
      >
        {childrenMenu.map((item, index) => {
          if (!item.name) {
            return <li key={index}>{item}</li>;
          }
          if (item.customerLink) {
            return (
              <li key={index} onClick={item.jumpPage} data-buried={item.dataBuried}>
                <div className={cn(itemClassName, styles.customerLink, 'fontMedium')}>
                  {item.iconName ? (
                    <Icon name={item.iconName} className={iconClassName}/>
                  ) : null}
                  {I18n.t(item.name)}
                </div>
              </li>
            );
          }
          return (
            <li key={index} data-buried={item.dataBuried}>
              {!item.children ? (
                (
                  <Link legacyBehavior={!item.modal} href={item.href} passHref onClick={(e) => {
                    e.preventDefault()
                    item.modal && item.modalFn()
                  }}>
                    <div className={cn(styles.childrenMenuItemContainer, childrenMenuItemClassName)}>
                      <div
                        className={cn(itemClassName, {
                          [styles.nextIcon]: item.hasNextIcon
                        })}
                      >
                        <div className={styles.itemContent}>
                          {item.iconName ? (
                            <Icon
                              name={item.iconName}
                              className={iconClassName}
                              style={item.iconStyle}
                            />
                          ) : null}
                          <div>
                            <span className={cn(styles.text, 'Medium')}>
                              {I18n.t(item.name)}
                            </span>
                          </div>
                        </div>
                        {item.hasNextIcon && (
                          <Icon name="nextIcon" className={styles.arrowRight} />
                        )}
                      </div>
                      <div className={styles.childrenMenuItemTips}>
                        {I18n.t(item.tips)}
                      </div>
                    </div>
                  </Link>
                )

              ) : (
                <Collapse
                  expandIconPosition="right"
                  className={cn(styles.popoverCollaps, styles.collapseFontSize)}
                  expandIcon={({ isActive }) => (
                    <Icon
                      // style={{ fontSize: '12px', color: '#696F7F' }}
                      name={isActive ? 'arrowUp' : 'arrowDown'}
                    />
                  )}
                >
                  <Collapse.Panel
                    key="panel"
                    header={[
                      <Icon
                        key="icon"
                        name={item.iconName}
                        className="history"
                      />,
                      <span
                        key="history"
                        className={cn(styles.title, 'Medium')}
                      >
                        {I18n.t(item.name)}
                      </span>
                    ]}
                  >
                    {item.children.map((i, index) => (
                      <Link legacyBehavior href={i.href} key={index}>
                        <a>
                          <div className={cn(styles.text, 'Medium')}>
                            {I18n.t(i.name)}
                          </div>
                        </a>
                      </Link>
                    ))}
                  </Collapse.Panel>
                </Collapse>
              )}
            </li>
          );
        })}
        {type === TYPE_PROFILE && renderLogout()}
      </ul>
    );
  }

  function renderArrow () {
    return (
      <Icon
        name={'arrowDown'}
        className={cn(styles.btnArrow, {
          [styles.arrowRotate]: menuVisible
        })}
      />
    );
  }

  function renderTitle () {
    if (type === TYPE_PROFILE) {
      return (
        <div className={styles.userInfo}>
          <div className={styles.userInfoAvatarContainer}>
            <Image
              src={avatar || defaultAvatar}
              className={styles.userInfoAvatar}
              width={72}
              height={72}
              alt="kikitrade"
            />
          </div>
          <div className={styles.userInfoNameContainer}>
            <div className={styles.userInfoName}>{nickName}</div>
          </div>
        </div>
      );
    }
  }

  function onVisibleChange (e) {
    setMenuVisible(!menuVisible);
    onVisibleChangePorps(e);
  }

  function renderLocaleList () {
    return (
      <ul className={styles.childrenMenu} style={{ width: `${popoverWidth}` }}>
        {childrenMenu.map((item, index) => (
          <li key={index} onClick={item.jumpPage} data-buried={item.dataBuried}>
            {/* eslint-disable-next-line */}
            <div
              className={cn(styles.childrenMenuItem, styles.languagePopver, {
                [styles.childrenMenuItemLanguage]:
                  helper.getLocaleKey() === item.flag
              })}
            >
              <span className={cn({ DINPro: item.flag === 'en' })}>
                {item.name}
              </span>
              <Icon name="select" className={styles.languagePopverIcon} />
            </div>
          </li>
        ))}
      </ul>
    );
  }

  const myBtnclassName = cn(styles.hoverColor, btnClassName);

  const btnContent = useMemo(() => {
    return (
      <>
        {primaryText}
        {hasArrow ? renderArrow() : null}
      </>
    );
  }, [primaryText, hasArrow, menuVisible, renderArrow]);

  return (
    <ConfigProvider autoInsertSpaceInButton={false}>
      <div ref={containerRef} className={containClassName}>
        {/* {childrenMenu.length > 0 || type === 'language' */}
        {childrenMenu.length > 0 ? (
          <Popover
            placement={placement}
            title={type === TYPE_PROFILE && renderTitle}
            content={type === TYPE_LANGUAGE ? renderLocaleList : renderMenu}
            // content={renderMenu}
            trigger={trigger}
            align={align || { offset: [0, 0] }}
            getPopupContainer={() => containerRef.current}
            onVisibleChange={onVisibleChange}
            destroyTooltipOnHide={true}
            overlayClassName={styles.childrenMenuPop}
          >
            <Button className={myBtnclassName} data-name={dataName}>
              {href ? (
                <Link legacyBehavior href={href}>
                  <a style={{ display: 'flex', alignItems: 'center' }}>
                  {btnContent}
                  </a>
                </Link>
              ) : (
                modal
                  ? <button onClick={modalFn}>
                  {btnContent}
                </button>
                  : <span style={{ display: 'flex', alignItems: 'center' }}>
                  {btnContent}
                </span>
              )}
            </Button>
          </Popover>
        ) : (
          <Button className={myBtnclassName}>
            {href ? (
              <Link legacyBehavior href={href}>
                <a className={styles.flexCenter}>
                {btnContent}
                </a>
              </Link>
            ) : (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {btnContent}
              </span>
            )}
          </Button>
        )}
      </div>
    </ConfigProvider>
  );
};

export const PopoverBtn = inject((rootStore: any) => ({
  userStore: rootStore.store.userStore
}))(observer(PopoverBtnFn));
