import React, { useState, useRef, useMemo } from 'react';
// import styles from './index.module.css';
import { InputSearch, Popover } from '@element/index';
import cn from 'classnames';
import { PopoverContent, NavbarSearch } from './Component';
import { inject, observer } from 'mobx-react'
import useInputChange from './InputHooks'
import useIsomorphicLayoutEffect from '@lib/hooks/useIsomorphicLayoutEffect'
import I18n from '@utils/i18n';

const Search = (props) => {
  const {
    className,
    marketStore: { hotSearchList, marketSymbols, marketCoins, hotSearch, fetchMarketCoin, fullMarketSymbols, fetchBaseCurrencies },
    searchType = 'navabar' // 'navabar' 'market'
  } = props;
  const [visible, setVisible] = useState(false);
  const { onInputChange, popType, filterSearchList, setPopType, text, settext } = useInputChange()
  const [fetch, sefetch] = useState(false)

  const containerRef = useRef();
  // const searchRef = useRef()

  useIsomorphicLayoutEffect(() => {
    async function fetchData () {
      await Promise.allSettled([
        hotSearch(),
        // fetchAssetCoinList(),
        fetchMarketCoin(),
        fullMarketSymbols(),
        fetchBaseCurrencies()
      ])
    }
    fetchData()
    sefetch(true)
  }, [])

  const searchNode = useMemo(() => {
    if (searchType === 'navabar') {
      return <NavbarSearch
              onChange={(e) => {
                onInputChange(e, marketSymbols, marketCoins)
              }}
              value={text}
              suffixCilck={(e) => {
                !e && setVisible(false)
              }}
      />
    }
    return <InputSearch
              type="text"
              showClear={true}
              className={'kiki-coinSearch-searchInput'}
              onChange={(e) => {
                onInputChange(e, marketSymbols, marketCoins)
              }}
              placeholder={I18n.t('search')}
              value={text}
        />
  }, [searchType, text, marketSymbols, marketCoins])

  const itemClick = () => {
    setVisible(false)
  }

  return (
    fetch && <div ref={containerRef} className={cn('searchContainer', className)}>
      <Popover
        placement='bottom'
        trigger="click"
        content={<PopoverContent
          type={popType}
          {...props}
          hotSearchList={hotSearchList}
          filterSearchList={filterSearchList}
          inputVal={text}
          itemClick={itemClick}
          // searchRef={searchRef}
        />}
        visible={visible}
        overlayClassName={'searchPop'}
        destroyTooltipOnHide={true}
        align={{ offset: [0, 2] }}
        getPopupContainer={() => containerRef.current}
        onSelect={onInputChange}
        onVisibleChange={(e) => {
          setVisible(e)
          if (!e) {
            setPopType('DEFAULT')
            settext('')
          }
        }}
      >
        {searchNode}
      </Popover>
    </div>
  )
}

export default inject(rootStore => {
  return {
    marketStore: rootStore.store.marketStore,
    withdrawalStore: rootStore.store.withdrawalStore
  }
})(observer(Search))
