import React, { useRef } from 'react'
import cn from 'classnames';
import { previewVideo } from './preview'
import { useIsomorphicLayoutEffect } from '@lib/hooks'
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';
import styles from './index.module.css'

// 组件地址: https://github.com/video-react/video-react
// API: https://video-react.js.org/components/player/

export const VideoComponent = (props) => {
  const { videoSource, width, height, fluid = false, poster } = props;
  const refVideo = useRef()
  const onClick = (e) => {
    e.stopPropagation()
    refVideo.current = previewVideo(props)
  }
  useIsomorphicLayoutEffect(() => {
    return () => {
      refVideo.current?.()
    }
  }, [])
  return (
    <div style={{ height: height, width: width }}>
      <Player
        fluid={fluid}
        width={width}
        height={height}
        src={videoSource}
        playsInline={false}
        className={styles.player}
        poster={poster}
      >
        <div className={styles.mask} onClick={onClick} />
        <div
          className={cn('video-react-button', 'video-react-big-play-button',
            'video-react-big-play-button-center', styles.bigPlayBtn)}
          onClick={onClick}
        >
          <span className='video-react-control-text' />
        </div>
        <BigPlayButton position="center" />
      </Player>
    </div>
  );
};
