import React, { useState } from 'react';

import { Router } from 'next/dist/client/router'

import { useOSWalletConnect } from '@/web3/hooks/particle/useOSWalletConnect'
import { isEoaWallet } from '@/web3/utils/particle/isEoaWallet'
import { openModal } from '@components/Element'
import { useIsomorphicLayoutEffect } from '@lib/hooks'
import I18n from '@utils/i18n';
interface HTMLCollectionOf<T extends Element> extends HTMLCollection {
  item(index: number): T;
  namedItem(name: string): T;
  [index: number]: T;
}
const isModalOpen = () => {
  return document.getElementsByClassName(rootClassName).length !== 0
}

const addHeaderClass = () => {
  const elm = (document.getElementsByClassName('ant-layout-header')as HTMLCollectionOf<HTMLElement>)[0]
  if (elm && elm?.style) {
    elm.style.position = 'relative'
    elm.style.zIndex = '11'
  }
}

const removeHeaderClass = () => {
  const elm = (document.getElementsByClassName('ant-layout-header')as HTMLCollectionOf<HTMLElement>)[0]
  if (elm && elm?.style) {
    elm.style.position = ''
    elm.style.zIndex = ''
  }
}

const ModalContent = (props) => {
  const { close } = props

  useIsomorphicLayoutEffect(() => {
    // handle router change event: https://stackoverflow.com/a/67063837
    Router.events.on('routeChangeStart', () => {
      if (isModalOpen()) {
        close()
        removeHeaderClass()
      }
    })
    window.addEventListener('message', (event) => {
      if (event.data === 'PARTICLE_WALLET_CLOSE_IFRAME') {
        // close click event
        close()
        removeHeaderClass()
      }
    })
  }, [])

  return (
      <div className='fixed  top-[64px] max-[768px]:top-[44px] right-0 bottom-0 bg-white'>
        {
          isEoaWallet()
            ? <EOAIframe/>
            : <OSIframe/>
        }
      </div>
  );
}
function setWalletUrlLanguage (url) {
  const locale = I18n.locale === 'zh-TC' ? 'zh-tw' : I18n.locale
  url = url.replace(/language=en/, `language=${locale}`)
  url = url.replace(/language=zh-CN/, `language=${locale}`)
  url = url.replace(/language=zh-HK/, `language=${locale}`)
  return url
}

const EOAIframe = () => {
  const [url, setUrl] = useState('')

  useIsomorphicLayoutEffect(() => {
    if (localStorage.getItem('walletUrl')) {
      const _url = setWalletUrlLanguage(localStorage.getItem('walletUrl'))
      setUrl(_url)
    }
  }, [])

  return (
    <>
      {url && <iframe src={url} id='particle-auth-core-iframe-wallet' title="programiz pro website" height="100%" className='particle-pwe-iframe w-[394px] max-[400px]:w-full max-[400px]:w-[100vw]'></iframe>}
    </>

  )
}

const OSIframe = () => {
  const [url, setUrl] = useState('')
  const { getWalletUrl, ParticleProvider } = useOSWalletConnect({
    locale: I18n.locale
  })

  useIsomorphicLayoutEffect(() => {
    if (ParticleProvider) {
      const url = getWalletUrl()
      setUrl(url)
    }
  }, [ParticleProvider])

  return (
    <>
      {url && <iframe src={url} id='particle-auth-core-iframe-wallet' title="programiz pro website" height="100%" className='particle-pwe-iframe w-[394px] max-[400px]:w-full max-[400px]:w-[100vw]'></iframe>}
    </>

  )
}

const rootClassName = 'mask-zindex-10'
const wrapClassName = 'top-[64px] z-10 max-[768px]:top-[44px] '
export const OpenParticleWalletModal = (props) => {
  if (isModalOpen()) return
  addHeaderClass()

  return openModal({
    title: '',
    footer: null,
    closable: false,
    mask: true,
    wrapClassName,
    rootClassName,
    centered: true,
    children: (close) =>
      <>
        <ModalContent {...props} close={close}/>
      </>
  })
}
