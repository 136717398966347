import React from 'react';
import I18n from '@utils/i18n';
import { openModal, Button } from '@element/index';
import styles from './index.module.css';

export const openMarginFailed = ({ textList }) => {
  return new Promise((resolve, reject) => {
    openModal({
      title: I18n.t('tip'),
      children: (
        <div className={styles.modalContent}>
          <div className={styles.contentWrapper}>
            {textList?.map((text, index) => (
              <p className={styles.p} key={index}>{text}</p>
            ))}
          </div>
        </div>
      ),
      footer: (close) => (
        <div className={styles.footer}>
          <Button type="ghost" className={styles.ikNow} onClick={() => {
            close()
            resolve(true);
          }}>{I18n.t('ok')}</Button>
        </div>
      )
    })
  })
}
