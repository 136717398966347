import React, { useRef } from 'react';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from '@lib/hooks';
import styles from './index.module.css';

export const InfiniteScroll = (props) => {
  const { className, children, fetchData, invisibleHeight } = props;
  const observerRef = useRef(null);
  const statusRef = useRef(null); // fetchData 的状态，loading 态中 不调用 fetchData
  const intersectionObserverRef = useRef(null);

  const unObserver = () => {
    intersectionObserverRef.current.unobserve(observerRef.current);
  };

  const callback = async ([target]) => {
    if (statusRef.current === 'loading' || target.intersectionRatio <= 0) {
      return;
    }
    try {
      statusRef.current = 'loading';
      await fetchData?.(unObserver);
    } catch (error) {}
    statusRef.current = 'end';
  };

  useIsomorphicLayoutEffect(() => {
    intersectionObserverRef.current = new IntersectionObserver(callback, {
      threshold: 0.0
    });
    intersectionObserverRef.current.observe(observerRef.current);
  }, []);

  return (
    <div className={cn(className, styles.parent)}>
      <div
        style={{ height: `${invisibleHeight}px` }}
        className={styles.observer}
        ref={observerRef}
      />
      {children}
    </div>
  );
};
