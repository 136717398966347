import React, { useMemo } from 'react';

import { Modal as AntdModal } from 'antd';
import cn from 'classnames'

import Icon from '@components/Icon';
import { Button } from '@element/index';
import I18n from '@utils/i18n';

import styles from './index.module.css'

export const Modal = (props) => {
  const {
    type,
    onOk,
    onCancel,
    classNames,
    okButtonProps = {},
    okText = I18n.t('confirm'),
    cancelText = I18n.t('common_cancel')
  } = props
  const renderFooter = useMemo(() => {
    if (type === 'primary') {
      return [
          <Button type='ghost' className={styles.button} key='cancel' onClick={onCancel}>
            {cancelText}
          </Button>,
          <Button
            key='ok'
            type='primary'
            onClick={onOk}
            className={styles.button}
            {...okButtonProps}
          >
            {okText}
          </Button>]
    }
  })
  return (
    <AntdModal
      className={cn(styles.modal, classNames)}
      footer={renderFooter}
      closeIcon={<Icon name='modalClose' style={{ fontSize: '22px' }} className={styles.closeIcon} onClick={props.onCancel}/>}
      {...props}
    >
        {props.children}
    </AntdModal>
  )
}
