import cn from 'classnames';

import { OpenParticleWalletModal } from '@/web3/components/wallet/modal/ParticleWallet'
import Icon from '@components/Icon';
import {
  COMMUNITY_PATHS,
  LOGIN_BEFORE_PATHS,
  MARKET_PATHS, NEWS_PATHS,
  PROJECTS_PATHS
} from '@constants/index';
import helper from '@utils/helper'
import I18n, { languageOptions } from '@utils/i18n';

import { DrawerKeyEum } from './const'
import { H5MeunItemTypes } from './index.type'

import styles from './index.module.less';

const changeLanguage = (item: { label?: string; value: any; key?: string; }) => helper.languageSwitch(item.value);

export const getH5Menu = (guideist, hideProfile): H5MeunItemTypes[] => {
  if (hideProfile) {
    return [
      {
        imageBgStyle: styles.imageBgStyle,
        noLoginShow: true,
        dataBuried: DrawerKeyEum.language,
        title: I18n.t('language'),
        imgUrl: require('@images/icon/locale.webp'),
        childrens: languageOptions.map((item, index) => (<div onClick={
          () => { changeLanguage(item) }
        } key={index} className={cn(styles.panelitem, {
          [styles.language]: helper.getLocaleKey() === item.key
        })}>
          <div className={cn(styles.title, styles.marginLeft)}>{item.label}</div>
          <Icon name='select' className={styles.selectIcon} />
        </div>))
      },
      {
        imageBgStyle: styles.imageBgStyle,
        dataBuried: DrawerKeyEum.download,
        imgUrl: require('@images/download_mobile.webp'),
        link: LOGIN_BEFORE_PATHS.DOWNLOAD,
        linkText: I18n.t('download')
      }]
  }
  return [
    {
      dataBuried: DrawerKeyEum.community_top,
      imageBgStyle: styles.imageBgStyle,
      imgUrl: require('@images/icon/community.webp'),
      link: COMMUNITY_PATHS.COMMUNITY,
      linkText: I18n.t('community_top')
    }, {
      imageBgStyle: styles.imageBgStyle,
      imgUrl: require('@images/nava_spot.webp'),
      noLoginShow: true,
      title: I18n.t('66nM140s'),
      childrens: [{
        link: MARKET_PATHS.MARKET,
        linkText: I18n.t('quotes'),
        dataBuried: 'web_market_submenutype_select_markets'
      }, {
        link: MARKET_PATHS.FIND,
        linkText: I18n.t('nEMVdvEh'),
        dataBuried: 'web_market_submenutype_select_discover'
      }]
    },
    {
      imageBgStyle: styles.imageBgStyle,
      dataBuried: DrawerKeyEum.projects,
      imgUrl: require('@images/projects_icon.webp'),
      link: PROJECTS_PATHS.PROJECTS,
      linkText: 'Web 3.0'
    },
    {
      imageBgStyle: styles.imageBgStyle,
      dataBuried: DrawerKeyEum.news,
      imgUrl: require('@images/news.webp'),
      link: NEWS_PATHS.INDEX,
      linkText: I18n.t('news')
    },
    {
      imageBgStyle: styles.imageBgStyle,
      noLoginShow: false,
      dataBuried: DrawerKeyEum.wallet1,
      imgUrl: require('@images/wallet1.webp'),
      linkText: I18n.t('wallet1'),
      modal: true,
      modalFn: OpenParticleWalletModal
    },
    {
      imageBgStyle: styles.imageBgStyle,
      dataBuried: DrawerKeyEum.more,
      noLoginShow: true,
      title: I18n.t('market_more'),
      imgUrl: require('@images/guide.webp'),
      className: styles.bottomBorder,
      childrens: guideist?.filter((i: { image: any; }) => i.image).map((i: { [x: string]: any; }) => ({
        link: i[`path_${helper.getLocaleKey()}`],
        linkText: i[`title_${helper.getLocaleKey()}`]
      }))
    }, {
      imageBgStyle: styles.imageBgStyle,
      noLoginShow: true,
      dataBuried: DrawerKeyEum.language,
      title: I18n.t('language'),
      imgUrl: require('@images/icon/locale.webp'),
      childrens: languageOptions.map((item, index) => (<div onClick={
        () => { changeLanguage(item) }
      } key={index} className={cn(styles.panelitem, {
        [styles.language]: helper.getLocaleKey() === item.key
      })}>
        <div className={cn(styles.title, styles.marginLeft)}>{item.label}</div>
        <Icon name='select' className={styles.selectIcon} />
      </div>))
    }, {
      imageBgStyle: styles.imageBgStyle,
      dataBuried: DrawerKeyEum.download,
      imgUrl: require('@images/download_mobile.webp'),
      link: LOGIN_BEFORE_PATHS.DOWNLOAD,
      linkText: I18n.t('download')
    }]
}
