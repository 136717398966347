import React from 'react';

import { Checkbox as AntdCheckBox } from 'antd';
import cn from 'classnames';

const AntdCheckBoxGroup = AntdCheckBox.Group;

export const Checkbox = ({ className = '', ...rest }) => {
  return (
    <AntdCheckBox
      className={cn('kiki-checkBox', className)}
      {...rest}
    />
  )
}

export const CheckboxRound = ({ className, ...rest }) => {
  return (
    <AntdCheckBox
      className={cn('kiki-checkBox', 'kiki-checkBox-round', className)}
      {...rest}
    />
  )
}

export const CheckboxGroup = ({ className, ...rest }) => {
  return (
    <AntdCheckBoxGroup
      className={cn('kiki-checkBox-group', className)}
      {...rest}
    />
  )
}
