import React, { useState } from 'react';

import { message } from 'antd';
import cn from 'classnames';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

import Icon from '@components/Icon';
import { TRADE_PATHS } from '@constants/index';
import { Input } from '@element/index';
import { useOfflineSymbols } from '@lib/hooks'
import I18n from '@utils/i18n';

export const CONTENT_TYPE = {
  DEFAULT: 'DEFAULT',
  SEARCH: 'SEARCH',
  EMPTY: 'EMPTY'
}
export const PopoverContent = (props) => {
  const { type = CONTENT_TYPE.DEFAULT, hotSearchList, filterSearchList, inputVal } = props;
  if (type === CONTENT_TYPE.DEFAULT || !inputVal) {
    return <DefaultMenu dataList={hotSearchList} {...props}/>
  } else if (type === CONTENT_TYPE.SEARCH && inputVal) {
    return <SearchMenu dataList={filterSearchList} {...props}/>
  } else {
    return <EmptyMenu {...props}/>
  }
}

const DefaultMenu = (props) => {
  const { dataList, className } = props
  const historyDataList = JSON.parse(localStorage.getItem('market_historySearch')) || []
  const [refresh, setrefresh] = useState(true)
  return (
    <div className={cn('searchPopContainer', className)}>
      <div className='overflowHidden'>
        <h2 className={cn('menuTitle', 'Medium')}>{I18n.t('Kr44y7jo')}</h2>
        <Menu dataList={dataList} {...props}/>
        <h2 className={cn('menuTitle', 'marginTop16')}>
          <span className={cn('Medium')} >{I18n.t('NTjiyDLg')}</span>
          <span className={cn('delIconContainer')} onClick={() => {
            localStorage.removeItem('market_historySearch')
            setrefresh(!refresh)
          }}><Icon name='delete' /></span>
        </h2>
        {
          refresh && (
            <Menu {...props} dataList={historyDataList} type='history' />
          )
        }
      </div>
    </div>
  );
}

const SearchMenu = (props) => {
  const { dataList, className } = props
  return (
    <div className={cn('searchPopContainer', className)}>
      <div className='overflowHidden'>
        <Menu dataList={dataList} {...props}/>
      </div>
    </div>
  )
}

const EmptyMenu = (props) => (
  <div className='searchPopContainerEmpty'>
    <span>{I18n.t('not.found')}</span>
  </div>
)

const Menu = (props) => {
  const { dataList = [], itemClick, type } = props;
  const { isOfflineSymbols } = useOfflineSymbols();
  const router = useRouter()
  const clickItem = (e, item) => {
    const coinCode = item.symbol;
    e.stopPropagation()
    const historySearch = JSON.parse((localStorage.getItem('market_historySearch') || '[]'))
    let data = [];
    if (type === 'history' && isOfflineSymbols(coinCode)) {
      // 搜索历史列表逻辑： 点击后判断是不是下线盘口，如果是需要弹窗提示，并删掉这条数据
      message.warning(I18n.t('symbol_offline_tip'))
      data = historySearch.filter(listItem => listItem.symbol !== coinCode);
      localStorage.setItem('market_historySearch', JSON.stringify(data))
      return;
    }
    // 最多记录5条历史搜索数据
    data = [item, ...historySearch.filter(i => i.symbol !== coinCode)].slice(0, 5)
    localStorage.setItem('market_historySearch', JSON.stringify(data))
    router.push({
      pathname: `${TRADE_PATHS.TRADE}/${coinCode}`
    })
    itemClick()
  }
  return (
    <ul className='menu'>
      {dataList && dataList.map((item, index) => {
        return (
        <li
          key={index}
          className='menuItem'
          onBlur={(e) => e.stopPropagation()}
          onClick={(e) => clickItem(e, item)}
        >
          <span className='menuItemImgContainer'>
          {
            item.logo && (
              <Image
                src={item.logo}
                width={24}
                height={24}
                className='image_borderRadius_24'
                layout='fixed'
                alt={'kikitrade'}
              />
            )
            }
          </span>
          <span className={cn('DINPro-Medium')}>
            {item.symbol.replace('_', '/')}
          </span>
        </li>
        )
      })}
    </ul>
  )
}

export function NavbarSearch (props) {
  const { suffixCilck } = props
  const [expand, setexpand] = useState(false);
  return (
    <div className='kiki-navabar-search-contain'>
      <Input
        {...props}
        className={cn({
          statusOne: expand,
          statusTwo: !expand
        })}
        // suffix={<Icon name='search'
        //   onClick={(e) => {
        //     e.stopPropagation()
        //     setexpand(!expand)
        //     suffixCilck(!expand)
        //   }}
        // />}
      />
      <Icon
        className='kiki-navabar-search-icon'
        name='search'
        onClick={(e) => {
          e.stopPropagation()
          setexpand(!expand)
          suffixCilck(!expand)
        }}
          />
    </div>
  )
}
