import React from 'react';

import cn from 'classnames';

import Icon from '@components/Icon';

import styles from './index.module.less';

interface TagProps {
  text: string;
  textClass?: string;
  iconName: string;
  iconClass?: string;
  className?: string; // 自定义背景色、字体色等
}

export const Tag = (props: TagProps) => {
  const { text, iconName, className, iconClass, textClass } = props;
  return (
    <div className={cn(styles.tagContainer, className)}>
      <Icon name={iconName} className={cn(styles.icon, iconClass)} />
      <div className={cn(styles.tagText, textClass, 'DINPro-Medium')}>
        {text}
      </div>
    </div>
  );
};
