export const DrawerKeyEum = {
  deposit1: 'deposit1',
  community_top: 'community_top',
  market: 'market',
  financial: 'financial',
  activity: 'activity',
  news: 'news',
  wallet1: 'wallet1',
  more: 'more',
  language: 'language',
  download: 'download',
  trade: 'trade',
  projects: 'projects'
}
