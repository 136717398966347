import React from 'react';

import { Switch as AntdSwitch } from 'antd'
import cn from 'classnames';

export const Switch = ({ className = '', ...rest }) => {
  return (
    <AntdSwitch className={cn('kiki-switch', className)} {...rest} />
  )
}
