import React, { useState } from 'react';
import reactDom from 'react-dom'
import NoCaptchaModal from './index';

const LoginNoCaptchaModal = ({ resolve, reject }) => {
  const [visible, setVisible] = useState(true)
  const onClose = () => {
    setVisible(false)
    reject();
  }
  const onFinish = (data) => {
    resolve(data)
    onClose()
  }
  return (
    <NoCaptchaModal modalVisible={visible} closeModal={onClose} destroyOnClose={true} onFinish={onFinish} />
  )
}

export const openLoginNoCaptchaModal = () => {
  const el = document.createElement('div');
  return new Promise((resolve, reject) => {
    reactDom.render(<LoginNoCaptchaModal resolve={resolve} reject={reject} />, el)
  })
}
