import React from 'react';

import { Table as AntdTable } from 'antd';
import cn from 'classnames';
import Image from 'next/legacy/image';

import { LoadingOutlined } from '@ant-design/icons';
// import { TableProps } from 'antd/lib/table';
import Icon from '@components/Icon';
import { Button } from '@element/index';
import I18n from '@utils/i18n';

import styles from './index.module.less';

// interface ITableProps extends TableProps {
//   className?: String;
//   wrapperMinH?: String;
//   emptyText?: String;
//   pagination?: String;
//   loading?: Boolean;
//   dataSource: Array<T>;
// }

export const Table = (props) => {
  const {
    className,
    wrapperMinH,
    emptyText,
    pagination,
    loading = false,
    dataSource = []
  } = props;
  const LoadingIcon = (
    <LoadingOutlined style={{ fontSize: '30px', color: '#6F40EE' }} spin />
  );
  function itemRender (current, type, originalElement) {
    if (type === 'prev') {
      return (
        <div className="prev-icon-container">
          <Icon name="preIcon" className="prev-icon" />
        </div>
      );
    }
    if (type === 'next') {
      return (
        <div className="next-icon-container">
          <Icon name="nextIcon" className="prev-icon" />
        </div>
      );
    }
    return originalElement;
  }

  // function expand({expanded, onExpand, record}) {
  //   expanded ? (
  //     <Image
  //       width={11}
  //       height={7}
  //       alt='KikiTrade'
  //       src={require('@images/topArrow.webp')}
  //       onClick={e => onExpand(record, e)}
  //     />
  //   ) : (
  //     <Image
  //       width={11}
  //       height={7}
  //       alt='KikiTrade'
  //       src={require('@images/bottomArrow.webp')}
  //       onClick={e => onExpand(record, e)}
  //     />
  //   )
  // }

  return (
    <AntdTable
      {...props}
      className={cn('kiki-table', className, {
        [styles.noborder]: dataSource.length === 0
      })}
      style={{ minHeight: wrapperMinH }}
      pagination={
        pagination
          ? { showSizeChanger: false, itemRender, ...pagination }
          : false
      }
      locale={{
        emptyText: emptyText || <DefaultEmptyText />
      }}
      loading={{
        indicator: LoadingIcon,
        spinning: loading
      }}
      // expand组件icon的更改
      expandIcon={({ expanded, onExpand, record }) =>
        expanded ? (
          <Image
            width={11}
            height={7}
            alt="KikiTrade"
            src={require('@images/topArrow.webp')}
            onClick={(e) => onExpand(record, e)}
          />
        ) : (
          <Image
            width={11}
            height={7}
            alt="KikiTrade"
            src={require('@images/bottomArrow.webp')}
            onClick={(e) => onExpand(record, e)}
          />
        )
      }
      // bordered={false}
    />
  );
};

export function DefaultEmptyText () {
  return (
    <div className="empty-container">
      <div className="empty-img">
        <Image
          src={require('@images/nodata.webp')}
          width={210}
          height={210}
          layout="fixed"
          alt={'kikitrade'}
        />
      </div>
      <span className="empty-text">{I18n.t('noData')}</span>
    </div>
  );
}

export function TableActionBtn (props) {
  const { actionText, onClick, className, type = 'text', ...rest } = props;
  // type: 'text' | 'button'
  if (type === 'button') {
    return (
      <Button
        {...rest}
        onClick={onClick}
        className={cn('kiki-table-action-btn-button', className)}
      >
        {actionText}
      </Button>
    );
  } else {
    return (
      <a
        {...rest}
        onClick={onClick}
        className={cn('kiki-table-action-btn', className)}
      >
        {actionText}
      </a>
    );
  }
}
// pagination = {
//   pageSize: 6, // 每页条数
//   showSizeChanger: false, // 是否展示 pageSize 切换器，当 total 大于 50 时默认为 true
//   itemRender, // 用于自定义页码的结构，可用于优化 SEO
//   total: 10 // 数据总数
//   current: 2, // 当前页数
//   position: ['bottomCenter'],
//   showQuickJumper: false // 是否可以快速跳转至某页
//   showLessItems: true
//   defaultCurrent: 1, // 默认的当前页数
//   defaultPageSize: 6, // 默认的每页条数
//   showTotal: ()=>{} // 用于显示数据总量和当前数据顺序
//   hideOnSinglePage: false // 只有一页时是否隐藏分页器
// }
