import React from 'react';

import Image from 'next/legacy/image'

import { getProfileMenu, jumpPageMap } from '@components/Navbar/const'
import defaultAvatar from '@images/avata.webp';
import { useIsomorphicLayoutEffect } from '@lib/hooks';
import { store } from '@store/index';

import { PopoverBtn } from '../index'

import styles from './index.module.less';

export const ProfilePopover = (props: { avatar?: string, isLogin?: boolean, isJumpLogin?: boolean }) => {
  const { avatar, isLogin, isJumpLogin } = props;
  const { fetchKycResult } = store.tradeStore;
  useIsomorphicLayoutEffect(() => {
    isLogin && fetchKycResult();
  }, [isLogin])
  const PROFILE_MENU = getProfileMenu()
  return isLogin ? (
    <PopoverBtn
      type='profile'
      isJumpLogin={isJumpLogin}
      primaryText={
        <div className={styles.avatarContainer}>
          <Image
            src={avatar || defaultAvatar}
            layout="fixed"
            width={32}
            height={32}
            className='image_borderRadius_32'
            alt=''
          />
          <span className={styles.avatarCover} />
        </div>
      }
      href=''
      hasArrow={false}
      btnClassName={styles.iconBtn}
      placement='bottomRight'
      childrenMenu={PROFILE_MENU.map(item => ({
        ...item,
        jumpPage: jumpPageMap[item.activekey]
      })) as any}
      popoverWidth='320px'
    />
  ) : null
}
