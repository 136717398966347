import React from 'react';

import cn from 'classnames'

import styles from './index.module.css'

export const Spin = (props) => {
  const { renderLoading, renderError, renderEmpty, status, children, className = '', loaddingClass } = props;
  const render = () => {
    return (
      <div className={cn(styles.container, loaddingClass)}>
        {status === 'loading' && renderLoading}
        {status === 'error' && renderError}
        {status === 'empty' && renderEmpty}
      </div>
    )
  }
  return (
    <div className={cn(styles.body, className, {
      [styles.overflow]: status !== 'success'
    })}>
      {status !== 'success' && render()}
      {children}
    </div>
  )
}
