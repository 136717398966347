import { favoriteDelete, favoriteAdd } from '@api/index';
import { message } from 'antd';
import I18n from '@utils/i18n';
// import { useState } from 'react';
import Router from 'next/router';
import { getLoginPath } from '@utils/index';
// import { useStore } from '@store/index';
// import { useObserver } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
// import { toJS } from 'mobx';
// import { memo } from 'react';

// const useUserStore = () => {
//   const { tradeStore } = useStore();
//   return useObserver(() => tradeStore)
// }

export const useFavorite = ({
  code,
  favoriteType,
  isLogin,
  mode = 'uncontrol',
  initIsFavorite = false,
  tradeStore
}) => {
  const { favoriteObject, fetchFavoriteList } = tradeStore;
  const [loading, setFetch] = useState(false);
  const [isFavorite, setisFavorite] = useState(initIsFavorite);

  const {
    onOkAddTextKey,
    onErrorAddTextKey,
    onOkRemoteTextKey,
    onErrorRemoteTextKey
  } = useMemo(() => {
    if (favoriteType === 'symbol') {
      return {
        onOkAddTextKey: I18n.t('add_favorite_success'),
        onErrorAddTextKey: I18n.t('add_favorite_failed'),
        onOkRemoteTextKey: I18n.t('yQzATKvC'),
        onErrorRemoteTextKey: I18n.t('ZGjzlY5H')
      };
    }
    return {
      onOkAddTextKey: I18n.t('bZSYgTky'),
      onErrorAddTextKey: I18n.t('i7CQomqX'),
      onOkRemoteTextKey: I18n.t('AwHfhnZ5'),
      onErrorRemoteTextKey: I18n.t('6J0A4bc1')
    };
  }, [favoriteType]);

  useEffect(() => {
    if (mode === 'uncontrol') {
      const key = favoriteType === 'zone' ? 'categoryKey' : 'symbol';
      const isFavorite = favoriteObject[`${favoriteType}s`]?.some(
        (i) => i[key] === code
      );
      setisFavorite(isFavorite);
    }
  }, [favoriteObject, favoriteType, code, mode]);

  const favoriteChange = async (e) => {
    e.stopPropagation();
    if (!isLogin) {
      Router.push(getLoginPath());
      return;
    }

    const res = await (isFavorite
      ? favoriteDelete({ items: code, favorite_type: favoriteType })
      : favoriteAdd({ items: code, favorite_type: favoriteType }));

    if (!res.success) {
      const error = isFavorite ? onErrorRemoteTextKey : onErrorAddTextKey;
      message.error(I18n.t(error));
      return;
    }
    const success = isFavorite ? onOkRemoteTextKey : onOkAddTextKey;
    message.success(I18n.t(success));

    if (mode === 'uncontrol') {
      fetchFavoriteList().then(() => {
        setFetch(!loading);
      });
      return;
    }
    setisFavorite(!isFavorite);
  };

  return {
    favoriteChange,
    isFavorite
  };
};
