import React, { useRef, useState } from 'react';
import reactDom from 'react-dom';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from '@lib/hooks'
import { Player, BigPlayButton } from 'video-react';
import { handleMaskScroll } from '@utils/index'
import { Icon } from '@components/index'
import styles from './index.module.css';

const Preview = (props) => {
  const { removePreview, videoSource, poster } = props;
  const playerRef = useRef(null);
  const [width, setWidth] = useState(1200)
  const [height, setHeight] = useState(600)
  useIsomorphicLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      const { contentBoxSize: [{ blockSize, inlineSize }] } = entry;
      if (inlineSize > 1200) {
        setWidth(1200)
      }
      if (inlineSize < 1200 && inlineSize > 960) {
        setWidth(960)
      }
      if (inlineSize < 960) {
        setWidth('100%')
      }
      if (blockSize < 600) {
        setHeight('100%')
      }
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.unobserve(document.body)
    }
  }, [])
  return (
    <div className={cn('ant-image-preview-root')}>
      <div className={cn('ant-image-preview-mask', styles.previewMask)} />
      <div className={cn('ant-image-preview-wrap')} >
        <div className={styles.closeWrapper} onClick={removePreview}>
          <Icon name="close" className={styles.close} />
        </div>
        <div className={styles.previewPlayer}>
          <Player
            fluid={false}
            width={width}
            height={height}
            src={videoSource}
            playsInline={true}
            ref={playerRef}
            poster={poster}
          >
            <BigPlayButton position="center" />
          </Player>
        </div>
      </div>
    </div>
  )
}

export const previewVideo = (props) => {
  let el = document.createElement('div');
  document.body.append(el)
  document.body.classList.add(styles.bodyOverflow)
  handleMaskScroll(true)
  const removePreview = () => {
    if (!el) return
    document.body?.removeChild?.(el)
    el = undefined;
    document.body?.classList?.remove?.(styles.bodyOverflow)
    handleMaskScroll(false)
  }
  reactDom.render(<Preview removePreview={removePreview} {...props} />, el)
  return removePreview
}
