import React from 'react';
import { Drawer as AntdDrawer } from 'antd';
// import { DrawerProps } from 'antd/lib/drawer';
import cn from 'classnames';

// export interface ICollapseProps extends DrawerProps {
// }

export function Drawer (props) {
  const { children, content, className, placement, ...rest } = props;
  return (
    <AntdDrawer
      className={cn('kiki-drawer', className)}
      placement={placement} // top | right | bottom | left
      destroyOnClose={true}
      maskClosable={true}
      {...rest}
    >
      {children || content}
    </AntdDrawer>
  )
}
