import React from 'react';

import Link from 'next/link';

import { Button, openModal } from '@element/index';
import I18n from '@utils/i18n';

import styles from './index.module.css';

export const disabledOpenMargin = () => {
  return new Promise((resolve, reject) => {
    openModal({
      title: I18n.t('tip'),
      children: (
        <div className={styles.modalContent}>
          <div className={styles.contentWrapper}>
            <p className={styles.p}>{I18n.t('LeverManageModalTip1')}</p>
            <p className={styles.link}>
              <Link legacyBehavior href="http://baidu.com">{I18n.t('marginTipMore')}</Link>
            </p>
          </div>
        </div>
      ),

      footer: (close) => (
        <div className={styles.footer}>
          <Button type="ghost" className={styles.ikNow} onClick={() => {
            close()
            resolve(true);
          }}>{I18n.t('IKnow')}</Button>
        </div>
      )
    })
  })
}

export const getIsTouchDisabledModal = () => {
  if (typeof localStorage === 'undefined') {
    return false
  }
  if (localStorage.getItem('disableOpenMargin') === '1') {
    return true
  }
  return false
}

export const setIsTouchDisabledModal = () => {
  if (typeof localStorage === 'undefined') {
    return
  }
  localStorage.setItem('disableOpenMargin', '1')
}
