import React, { Fragment, useState, useRef } from 'react';
import { useIsomorphicLayoutEffect } from '@lib/hooks'

type IGoParams = {
  delta: number;
  data?: any;
}

type OnPrev = <T = any>(params?: T) => void;
type OnNext = <T = any>(params?: T) => void;
type OnGo = (params: number | IGoParams) => void;

export interface IStep<T = any> {
  /**
   * 跳转到上一步 params: 例如当前用户在第二步，点击上一步时，可以将当前第二步用户所进行的操作数据进行传入，保存当前步骤的数据
   */
  onPrev: OnPrev;
  /**
   * 跳转到下一步 params: 例如当前用户在第二步，点击下一步时，可以将当前第二步用户所进行的操作数据进行传入，保存当前步骤的数据
   */
  onNext: OnNext;
  /**
   * 跳转到整个流程的任意一步，可以直接传入 number ，表示跳转到整个流程的任意一步
   * 也可以传入 object，delta 表示跳转到整个流程的任意一步 data 为当前流程的数据
   */
  onGo: OnGo;
  /**
   * 当前流程所保存的数据，例如当用户点击了上一步，现在回到了第一步，data 会将之前第一步所保存的数据传入
   * 如果是 component 中的data，data 为整个流程的所有数据
   */
  data?: T;
  /**
   * 上下文 currStep 当前在第几步 data 整个流程所保存的数据
   */
  context?: {
    currStep: number;
    data: {
      [key: string]: any;
    };
  };
}

export type FlowComponent<T> = IStep<T> & {
  /**
   * 当前步骤的组件
   */
  children: React.ReactNode;
  /**
   * 当前进行到第几步
   */
  currStep: number;
}

export interface StepFlowProps<T = any, K = any> {
  component?: (props: FlowComponent<K>) => React.ReactNode;
  steps: ((props: IStep) => React.ReactNode)[];
  defaultStepNo?: number;
  onComplete?: (data?: T) => void;
}

export const StepFlow: React.FC<StepFlowProps> = (props) => {
  const { defaultStepNo, steps, onComplete, component } = props;
  const [currentStepNo, setCurrentStepNo] = useState<number>(defaultStepNo ?? 0);
  const dataRef = useRef<{[key: string]: any}>({})

  const cacheRef = useRef<{
    total: number,
    lastIndex: number,
  }>(null)

  const onPrev: OnPrev = (params) => {
    dataRef.current[currentStepNo] = params;
    const prevNo = currentStepNo - 1;
    prevNo >= 0 && setCurrentStepNo(prevNo);
  }

  const onNext: OnNext = (params) => {
    dataRef.current[currentStepNo] = params;
    const nextNo = currentStepNo + 1;
    nextNo <= cacheRef.current.lastIndex ? setCurrentStepNo(nextNo) : onComplete?.(dataRef.current);
  }

  const onGo: OnGo = (params) => {
    const delta = (params as IGoParams)?.delta ?? params;
    if (typeof delta === 'number' && delta >= 0 && delta <= cacheRef.current.lastIndex) {
      setCurrentStepNo(delta)
    }
    if ((params as IGoParams)?.data) {
      dataRef.current[currentStepNo] = (params as IGoParams)?.data
    }
  }

  useIsomorphicLayoutEffect(() => {
    cacheRef.current = {
      total: steps.length,
      lastIndex: steps.length - 1
    }
  }, [steps])

  return (
    <Fragment>
      {component ? component({
        onPrev,
        onNext,
        onGo,
        data: dataRef.current,
        currStep: currentStepNo,
        children: steps[currentStepNo]({
          onPrev,
          onNext,
          onGo,
          data: dataRef.current[currentStepNo],
          context: {
            currStep: currentStepNo,
            data: dataRef.current
          }
        })
      }) : steps[currentStepNo]({
        onPrev,
        onNext,
        onGo,
        data: dataRef.current[currentStepNo],
        context: {
          currStep: currentStepNo,
          data: dataRef.current
        }
      })
    }
    </Fragment>
  )
}
