
import React from 'react';

import Image from 'next/legacy/image'
import Link from 'next/link'

import { APP_ANDROID_PATH, APP_IOS_PATH } from '@constants/paths';
import I18n from '@utils/i18n';

import { KikiLogo } from '../KikiLogo';

import styles from './index.module.less';

export const Down: React.FC = () => {
  return (
    <div className={styles.down}>
      <div className={styles.content}>
        <div className={styles.block}>
          <div className={styles.img}>
            <KikiLogo
              width='110px'
              height='32px'
            />
          </div>
          <span className={styles.text}>{I18n.t('start_crypto_trading')}</span>
        </div>
        <div className={styles.btnWrapper}>
          <Link legacyBehavior href={APP_IOS_PATH}><a target="_blank"><div className={styles.btn}>App Store</div></a></Link>
          <Link legacyBehavior href={APP_ANDROID_PATH}><a target="_blank"><div className={styles.btn}>Google Play</div></a></Link>
        </div>
      </div>
      <div className={styles.qrcode}>
        <div className={styles.img}>
          <Image
            src={require('@images/login/qrcode.webp')}
            layout="fixed"
            width={100}
            height={100}
            alt=''
          />
        </div>
        <span className={styles.text}>{I18n.t('common_scan_download')}</span>
      </div>
    </div>
  )
}
