import React from 'react';

import { Button } from 'antd';
import cn from 'classnames';

import Icon from '@components/Icon';

import styles from './index.module.css';

// export interface IPaginationProps {
//   className?: String;
//   preDisable?: Boolean;
//   nextDisable?: Boolean;
//   clickPre: Function;
//   clickNext: Function;
// }

export function Pagination (props) {
  const { className, preDisable = false, nextDisable = false, clickPre, clickNext } = props;
  const myClassName = cn(
    styles.kikiPagination,
    className
  )
  return (
    <div className={myClassName}>
      {!preDisable && <Button className={styles.prevIconContainer} disabled={preDisable} onClick={clickPre}>
        <Icon name='preIcon' className={styles.prevIcon} />
      </Button>}
      {!nextDisable && <Button className={styles.nextIconContainer} disabled={nextDisable} onClick={clickNext}>
        <Icon name='nextIcon' className={styles.nextIcon} />
      </Button>}
    </div>

  )
}
