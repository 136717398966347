import React, { useState } from 'react';
import reactDom from 'react-dom';

import { Drawer as AntdDrawer } from 'antd';
import type { DrawerProps } from 'antd/es/drawer';
import cn from 'classnames';

import { Icon } from '@components/index';
import { THEME } from '@constants/index';
import { useClient } from '@lib/hooks';

import styles from './index.module.less';
import { resolve } from 'styled-jsx/css';

type CloseType = () => void;

type AntDrawerProps = Omit<DrawerProps, 'extra' | 'footer' | 'children'>;

type DrawerPropsType = AntDrawerProps & {
  content:
    | DrawerProps['children']
    | ((params: {close: CloseType, resolve: DrawerPropsType['resolve']}) => DrawerProps['children']);
  footer?:
    | DrawerProps['footer']
    | ((close: CloseType) => DrawerProps['footer']);
  extra?:
    | DrawerProps['children']
    | ((close: CloseType) => DrawerProps['extra']);
  theme?: Theme;
  onClose?: (close: CloseType) => () => void;
  resolve: (params: any) => void;
};

export const Drawer = (props: DrawerPropsType) => {
  const isPC = useClient();
  const {
    content,
    extra,
    footer,
    className,
    placement,
    closeIcon,
    theme,
    onClose,
    resolve,
    ...rest
  } = props;
  const [open, setOpen] = useState(true);
  const close = () => {
    setOpen(false);
    document.body.classList.remove(styles.bodyOverflow);
    resolve('cancel')
  };
  const renderContent = () => {
    if (typeof content === 'function') {
      return content({ close, resolve });
    }
    return content;
  };
  const renderFooter = () => {
    if (typeof footer === 'function') {
      return footer(close);
    }
    return footer;
  };
  const renderExtra = () => {
    if (typeof extra === 'function') {
      return extra(close);
    }
    return extra;
  };
  return (
    <AntdDrawer
      className={cn(styles['kiki-drawer'], 'qqqq', {
        [styles['kiki-drawer-dark']]: theme === THEME.DARK
      })}
      open={open}
      destroyOnClose={true}
      maskClosable={true}
      extra={renderExtra()}
      footer={renderFooter()}
      onClose={close}
      width={isPC ? 640 : '100%'}
      placement="right"
      closeIcon={
        props.closeIcon || (
          <div
            className={cn(styles.closeIconContainer, {
              [styles.darkCloseIconContainer]: theme === THEME.DARK
            })}
          >
            <Icon
              name="modalClose"
              className={cn(styles.closeIcon, {
                [styles.darkCloseIcon]: theme === THEME.DARK
              })}
              onClick={onClose?.(close) ?? close}
            />
          </div>
        )
      }
      {...rest}
    >
      {renderContent()}
    </AntdDrawer>
  );
};

export const openDrawer = (
  props: Omit<DrawerPropsType, 'resolve' | 'reject'>
) => {
  return new Promise<void>((resolve, reject) => {
    const el = document.createElement('div');
    document.body.classList.add(styles.bodyOverflow);
    reactDom.render(<Drawer {...props} resolve={resolve} />, el);
  });
};
