import React from 'react';

import cn from 'classnames';
import { useRouter } from 'next/router';

import Icon from '@components/Icon';
import { THEME } from '@constants/index';
import { languageOptions } from '@utils/i18n';
import { helper } from '@utils/index'

import { PopoverBtn } from '../index'

import styles from './index.module.less';

export const LanguageSwitch = (props: { theme?: Theme }) => {
  const router = useRouter();
  const { theme = THEME.HIGHLIGHT } = props;
  return (
    <PopoverBtn
      primaryText={<Icon name='globalLine' style={{ fontSize: '20px' }}/>}
      href=''
      hasArrow={false}
      btnClassName={cn(
        styles.iconBtn,
        { [styles.dark]: theme === THEME.DARK }
      )}
      placement='bottomRight'
      childrenMenu={languageOptions.map((item, index) => {
        return {
          activekey: index,
          name: item.label,
          flag: item.key,
          customerLink: `/${item.value}${router.pathname}`,
          jumpPage () {
            helper.languageSwitch(item.value)
          }
        }
      })}
      type='language'
      popoverWidth='132px'
    />
  )
}
