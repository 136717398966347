import React, { useState } from 'react';

import cn from 'classnames';
import omit from 'lodash/omit'
import Image from 'next/legacy/image';
import Link from 'next/link';

import Icon from '@components/Icon';
import { COMMUNITY_PATHS, NEWS, PROJECTS_PATHS } from '@constants/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect';
import { getRemoteConfigValue } from '@utils/firebase';
import I18n from '@utils/i18n';

import {
  MARKET_MENU
} from '../../const';
import { GuideList } from '../GuideList';
import { PopoverBtn } from '../index';

import styles from './index.module.less';

export const NavbarWebLeft = (props) => {
  const [focusBtn, setfocusBtn] = useState();
  const [firebasedata, setData] = useState({
    guideist: []
  });
  const { guideist = [] } = firebasedata;
  useIsomorphicLayoutEffect(() => {
    const firbaseData = {};
    Promise.all([
      getRemoteConfigValue('beginner_guide'),
      getRemoteConfigValue('home_post_active')
    ]).then((res) => {
      try {
        firbaseData.guideist = JSON.parse(res[0]?._value || '[]');
        firbaseData.postActiveList = JSON.parse(res[1]?._value || '[]');
      } catch (error) {
        console.log(error);
      }
      setData(firbaseData);
    });
  }, []);

  const tabMapNew = [
    { // 菜单
      type: 'popoverBtn',
      // spanClassName: styles.span,
      primaryText: <Icon name="guide" style={{ fontSize: '20px' }} />,
      btnClassName: cn(styles.guidebtn, 'mr20', {
        [styles.focus]: focusBtn === 'guide'
      }),
      childrenMenClassname: styles.guideistChildrenMenClassname,
      childrenMenu: [<GuideList key="guide" data={guideist} />],
      onVisibleChangePorps: (e) => {
        if (e) {
          setfocusBtn('guide');
        } else {
          setfocusBtn('');
        }
      }
    },
    { // 社区
      type: 'btn',
      dataBuried: 'community_top',
      href: COMMUNITY_PATHS.COMMUNITY,
      btnNode: (
        <a>
          <span className={cn('Medium', styles.fireTitle)}>
            {I18n.t('community_top')}
          </span>
          <Image
            src={require('@images/fire.webp')}
            layout="fixed"
            width={10}
            height={12}
            alt=""
            className={styles.fireImg}
          />
        </a>
      )
    },
    { // 市场
      type: 'popoverBtn',
      // spanClassName: styles.span,
      primaryText:
        <span className={cn(styles.marketText, 'Medium')}>{I18n.t('66nM140s')}</span>,
      btnClassName: cn(styles.marketbtn, 'mr20'),
      childrenMenClassname: styles.nextIconContainer,
      childrenMenu: MARKET_MENU,
      popoverWidth: '284px',
      dataBuried: 'market'
    },
    { // projects
      type: 'btn',
      dataBuried: 'information',
      href: PROJECTS_PATHS.PROJECTS,
      btnNode: (
        <span>
          <a className={cn('DINPro-Medium', styles.news, styles.activityTag)}>
            {'Web 3.0'}
          </a>
        </span>
      )

    },
    { // news
      type: 'btn',
      dataBuried: 'information',
      href: NEWS,
      btnNode: <a className={cn('DINPro-Medium', styles.news)}>
        {'NEWS'}
      </a>
    }
  ];

  // const tabMap = [
  //   { // 菜单
  //     type: 'popoverBtn',
  //     // spanClassName: styles.span,
  //     primaryText: <Icon name="guide" style={{ fontSize: '20px' }} />,
  //     btnClassName: cn(styles.guidebtn, 'mr20', {
  //       [styles.focus]: focusBtn === 'guide'
  //     }),
  //     childrenMenClassname: styles.guideistChildrenMenClassname,
  //     childrenMenu: [<GuideList key="guide" data={guideist} />],
  //     onVisibleChangePorps: (e) => {
  //       if (e) {
  //         setfocusBtn('guide');
  //       } else {
  //         setfocusBtn('');
  //       }
  //     }
  //   },
  //   // { // 入金
  //   //   type: 'btn',
  //   //   dataBuried: 'deposit1',
  //   //   href: '/deposit',
  //   //   btnNode: <a className="Medium">{I18n.t('deposit1')}</a>
  //   // },
  //   { // 社区
  //     type: 'btn',
  //     dataBuried: 'community_top',
  //     href: COMMUNITY_PATHS.COMMUNITY,
  //     btnNode: (
  //       <a>
  //         <span className={cn('Medium', styles.fireTitle)}>
  //           {I18n.t('community_top')}
  //         </span>
  //         <Image
  //           src={require('@images/fire.webp')}
  //           layout="fixed"
  //           width={10}
  //           height={12}
  //           alt=""
  //           className={styles.fireImg}
  //         />
  //       </a>
  //     )
  //   },
  //   { // 市场
  //     type: 'popoverBtn',
  //     // spanClassName: styles.span,
  //     primaryText:
  //       <span className={cn(styles.marketText, 'Medium')}>{I18n.t('66nM140s')}</span>,
  //     btnClassName: cn(styles.marketbtn, 'mr20'),
  //     childrenMenClassname: styles.nextIconContainer,
  //     childrenMenu: MARKET_MENU,
  //     popoverWidth: '284px',
  //     dataBuried: 'market'
  //   },
  //   // { // 交易
  //   //   type: 'popoverBtn',
  //   //   // spanClassName: styles.span,
  //   //   primaryText: <span className={cn(styles.marketText, 'Medium')}>
  //   //     {I18n.t('asset_exchange')}
  //   //   </span>,
  //   //   btnClassName: cn(styles.marketbtn, 'mr20'),
  //   //   childrenMenClassname: styles.nextIconContainer,
  //   //   childrenMenu: TRANSACTION_MENU,
  //   //   popoverWidth: '284px',
  //   //   dataBuried: 'market'
  //   // },
  //   // { // 入金
  //   //   type: 'btn',
  //   //   dataBuried: 'nava-spot',
  //   //   href: `${TRADE_PATHS.TRADE}/BTC_USDT`,
  //   //   btnNode: <a className="Medium">{I18n.t('asset_exchange')}</a>
  //   // },
  //   // { // 理财
  //   //   type: 'popoverBtn',
  //   //   primaryText: <span className={cn(styles.marketText, 'Medium')}>
  //   //     {I18n.t('savings')}
  //   //   </span>,
  //   //   btnClassName: cn(styles.marketbtn, 'mr20'),
  //   //   childrenMenClassname: styles.nextIconContainer,
  //   //   childrenMenu: FINACIAL_MENU,
  //   //   popoverWidth: '284px',
  //   //   dataBuried: 'financial'
  //   // },
  //   { // 活动
  //     type: 'popoverBtn',
  //     primaryText: <span
  //       className={cn(styles.marketText, styles.activityTag, 'Medium')}
  //     >
  //       {I18n.t('WaEmnBbe')}
  //     </span>,
  //     btnClassName: cn(styles.marketbtn, 'mr20'),
  //     childrenMenClassname: styles.nextIconContainer,
  //     childrenMenu: ACTIVITY_MENU,
  //     popoverWidth: '284px',
  //     dataBuried: 'invite_friend'
  //   },
  //   { // projects
  //     type: 'btn',
  //     dataBuried: 'information',
  //     href: PROJECTS_PATHS.PROJECTS,
  //     btnNode: (
  //       <span>
  //         <a className={cn('DINPro-Medium', styles.news, styles.activityTag)}>
  //           {'Web 3.0'}
  //         </a>
  //       </span>
  //     )

  //   },
  //   { // news
  //     type: 'btn',
  //     dataBuried: 'information',
  //     href: NEWS,
  //     btnNode: <a className={cn('DINPro-Medium', styles.news)}>
  //       {'NEWS'}
  //     </a>
  //   }
  // ];

  const _tab = tabMapNew

  return (
    <div className={styles.headerLeft}>
      {_tab.map((item, index) => {
        if (item.type === 'popoverBtn') {
          return <span data-buried={item.dataBuried} key={index}>
            <PopoverBtn
              spanClassName={styles.span}
              // trigger= 'click'
              href=""
              hasArrow={true}
              dataName="kiki-navabar-web-btn-more"
              placement="bottomLeft"
              {...omit(item, ['dataBuried', 'type'])}
            />
          </span>
        } else {
          return <span data-buried={item.dataBuried} className={styles.newsContain} key={index}>
            <Link legacyBehavior href={item.href}>
              {item.btnNode}
            </Link>
          </span>
        }
      })}
    </div>
  );
};
