import React from 'react';

import { Radio as AntdRadio, RadioGroupProps, RadioProps } from 'antd';
import cn from 'classnames';
import omit from 'lodash/omit';

import styles from './index.module.less';

const AntdRadioGroup = AntdRadio.Group;

interface IRadioProps extends RadioProps {
  isChecked?: boolean;
}

export const Radio: React.FC<IRadioProps> = (props) => {
  return (
    <AntdRadio
      {...omit(props, ['className', 'isChecked'])}
      className={cn('kiki-input', props.className, {
        [styles['radio-checked']]: props.isChecked
      })}
    />
  )
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  return (
    <AntdRadioGroup
      {...props}
      className={cn('kiki-input', props.className)}
    />
  )
}
