import { useState } from 'react';

import { ParticleService } from 'particle-connect'

// import { isEoaWallet } from '@/web3/utils/particle/isEoaWallet'
import { useIsomorphicLayoutEffect } from '@lib/hooks'

export const useOSWalletConnect = (options = {}) => {
  const [ParticleProvider, setParticleProvider] = useState(null)

  useIsomorphicLayoutEffect(() => {
    // if (!isEoaWallet()) {
    initProvider(options)
    // }
  }, [])

  const initProvider = async (options) => {
    const _ParticleService = new ParticleService(options)
    await _ParticleService.init(options)
    _ParticleService.setAuthTheme(options?.theme)
    _ParticleService.setLanguage(options?.locale)
    setParticleProvider(_ParticleService)
  }

  const connectOSWallet = async (walletType) => {
    return await ParticleProvider?.connectWallet('particle', { preferredAuthType: walletType })
  }

  const disconnectWallet = async () => {
    await ParticleProvider?.disconnectWallet()
  }

  const getAddress = async () => {
    return await ParticleProvider?.getWalletAddress()
  }

  const getChainName = async () => {
    return await ParticleProvider?.getWalletChainName()
  }

  const getSignture = async (params, address) => {
    return await ParticleProvider?.getWalletSignture(params, address)
  }

  const getWalletUrl = () => {
    const url = ParticleProvider?.getWalletUrl()
    return url
  }

  return {
    initProvider,
    ParticleProvider,
    connectOSWallet,
    getAddress,
    getChainName,
    getSignture,
    getWalletUrl,
    disconnectWallet
  }
}
