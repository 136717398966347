import React from 'react';

import { Tabs as AntdTabs } from 'antd';
import { TabPaneProps, TabsProps } from 'antd/lib/tabs';
import cn from 'classnames';

import { THEME } from '@constants/index';

const AntdTabPane = AntdTabs.TabPane;

interface ITabsProps extends Omit<TabsProps, 'type'> {
  type?: TabsProps['type'] | 'primary' | 'login' | 'tradeDrawer' | 'marketTab' | 'marketTabCoin';
  theme?: Theme;
}

interface ITabPaneProps extends TabPaneProps {
  type?: TabsProps['type'] | 'primary' | 'login' | 'tradeDrawer' | 'marketTab' | 'marketTabCoin';
  theme?: Theme;
}

export const Tabs: React.FC<ITabsProps> = (props) => {
  const { className, type = '', theme = THEME.HIGHLIGHT, ...restProps } = props
  const myClassName = cn('kiki-tabs', className, {
    'primary-tabs': type === 'primary',
    'login-tabs': type === 'login',
    'trade-drawer-tabs': type === 'tradeDrawer' || type === 'marketTab',
    // 'market-tabs-one': type === 'marketTab',
    'market-tabs-two': type === 'marketTabCoin',
    dark_tabs: theme === THEME.DARK
  }, {
    borderRadius: type === 'marketTab'
  })
  return (
    <AntdTabs
      {...restProps}
      className={myClassName}
    />
  )
}

export const TabPane: React.FC<ITabPaneProps> = (props) => {
  const { className, type = '', theme = THEME.HIGHLIGHT, ...restProps } = props
  const myClassName = cn('kiki-tabs-pane', className, {
    'primary-tabs': type === 'primary',
    'login-tabs-pane': type === 'login',
    'trade-drawer-tabs': type === 'tradeDrawer' || type === 'marketTab',
    'market-tabs-two': type === 'marketTabCoin',
    dark_tabPane: theme === THEME.DARK
  }, className)
  return (
    <AntdTabPane
      className={myClassName}
      {...restProps}
    />
  )
}
