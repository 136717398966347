import React from 'react';

import { Popover, TipIcon } from '@element/index';
import I18n from '@utils/i18n';

import styles from './index.module.css';

export const MarginHelp = () => {
  return (
    <Popover contenTitle={I18n.t('tip')} buttonType='1' mode="modal" content={<div className={styles.content}>
      <p className={styles.p}>{I18n.t('MarginOpenTipModalTip1')}</p>
      <p className={styles.p}>{I18n.t('MarginOpenTipModalTip2')}</p>
      <p className={styles.p}>{I18n.t('MarginOpenTipModalTip3')}</p>
    </div>} hasArrow overlayClassName={styles.popover} placement="bottomRight" align={{ targetOffset: ['-16', '-8'] }}>
      <div className={styles.iconWrapper}>
        <TipIcon/>
      </div>
    </Popover>
  )
}
