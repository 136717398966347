import React, { useState } from 'react';

import generatePicker from 'antd/lib/date-picker/generatePicker';
import localeEN from 'antd/lib/locale/en_US';
import localeCN from 'antd/lib/locale/zh_CN';
import localeTC from 'antd/lib/locale/zh_HK';
import cn from 'classnames';
import dayjs from 'dayjs';
// import { DatePicker as AntdDatePicker, ConfigProvider } from 'antd';
// import { RangePickerProps } from 'antd/lib/date-picker';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

import Icon from '@components/Icon';
import I18n from '@utils/i18n';
// import localeCN from 'antd/es/date-picker/locale/zh_CN';
// import localeTC from 'antd/es/date-picker/locale/zh_TW';
// import localeEN from 'antd/es/date-picker/locale/en_US';

const AntdDatePicker = generatePicker(dayjsGenerateConfig);

// 和产品和UI沟通过了 ranges 按照 antd 的排版放在日历下方

const AntdRangePicker = AntdDatePicker.RangePicker;

// interface IProps extends RangePickerProps {
//   // disabledDate & futureDisble = true 只能二选一
//   futureDisble?: Boolean;
// }

export function RangePicker (props) {
  const {
    className, ranges,
    allowClear = false, label,
    disabledDate, futureDisble = false,
    onChange: propsOnchange, showTime
  } = props;
  const [open, setOpen] = useState(false);
  const Ilocale = I18n.locale === 'zh-CN' ? localeCN : (I18n.locale === 'zh-TC' ? localeTC : localeEN)
  const suffixIcon = (<span className={cn('ranger-suffix-icon', {
    'ranger-suffix-icon-up': open
  })}>
    <Icon name="arrowDown" fontSize="8"/>
    {/* <Image
      src={require('@images/icon/arrowDownFill.webp')}
      layout="fixed"
      width={8}
      height={6}
      alt='kikitrade'
    /> */}
  </span>)

  function disabledAfterCurrent (current) {
    return current > dayjs().endOf('day');
  }

  const onChange = (date) => {
    if (showTime) {
      propsOnchange(date)
      return
    }
    propsOnchange([date[0].startOf('day'), date[1].endOf('day')])
  }

  return (
  // <ConfigProvider locale={Ilocale}>
      <div className='kiki-ranger-picker-container'>
        <span className='kiki-ranger-picker-label'>{label || I18n.t('datePicker_time')}</span>
        <AntdRangePicker
          {...props}
          onChange={onChange}
          showTime={showTime}
          className={cn('kiki-ranger-picker', className)}
          dropdownClassName={cn('kiki-ranger-picker', className)}
          locale={Ilocale.DatePicker}
          ranges={
            ranges ||
            {
              [I18n.t('datePicker_today')]: [dayjs(), dayjs()],
              [I18n.t('datePicker_seven_day')]: [dayjs().subtract(1, 'week'), dayjs()],
              [I18n.t('datePicker_one_month')]: [dayjs().subtract(1, 'month'), dayjs()],
              [I18n.t('datePicker_six_month')]: [dayjs().subtract(6, 'month'), dayjs()]
            }}
            onOpenChange={(open) => setOpen(open)}
            allowClear={allowClear}
            separator={<span className='ranger-separator' />}
            suffixIcon={suffixIcon}
            disabledDate={futureDisble === true ? disabledAfterCurrent : disabledDate}
        />
      </div>
  // </ConfigProvider>
  )
}

// export default function Test (props) {
//   return (
//       <div className={loginStyles.container} style={{ margin: '10px auto' }}>
//         <div style={{ width: '232px' }}>
//             <RangePicker
//               futureDisble={true}
//             />
//         </div>
//       </div>
//   )
// }
