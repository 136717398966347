import React from 'react';

import cn from 'classnames';

import Icon from '@components/Icon';

import styles from './index.module.less';

interface TipProps {
  fontSize?: string | '8px';
  /** 自定义背景色等 */
  className?: string;
}

export const TipIcon = (props: TipProps) => {
  const { className, fontSize = '8px' } = props;
  return (
    <span className={cn(styles.helpIcon, className)}>
      <Icon name='question' style={{ fontSize: fontSize }}/>
    </span>
  );
};
