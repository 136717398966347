import React, { useState } from 'react';
import reactDom from 'react-dom';

import { Modal as AntModal, ModalProps } from 'antd';
import cn from 'classnames';
import omit from 'lodash/omit'
import { NextRouter } from 'next/router';

import { Icon } from '@components/index'
import { THEME } from '@constants/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks';
import { handleMaskScroll } from '@utils/index';

import styles from './index.module.less';

type IClose = () => void;

type AntModalProps = Omit<ModalProps, 'onCancel' | 'onOk' | 'footer' | 'children' | 'cancelButtonProps' | 'okButtonProps'>;

export interface IModalProps extends AntModalProps {
  onCancel?: (close: IClose) => ModalProps['onCancel'];
  onOk?: (close: IClose) => ModalProps['onOk'];
  footer?: ModalProps['footer'] | ((close: IClose) => ModalProps['footer']);
  children?: ModalProps['children'] | ((close: IClose) => ModalProps['children']);
  router?: NextRouter;
  inPath?: string;
  theme?: Theme;
  rootClassName?: string,
  onClose?: (close: IClose) => () => void;
  dataBuried?: string,
  cancelButtonProps?: {
    'data-buried': string;
  }
  okButtonProps?: {
    'data-buried': string;
  }
}

const Modal: React.FC<IModalProps> = (props) => {
  const { router, theme = THEME.HIGHLIGHT, onClose } = props;
  const [visible, setVisible] = useState(true);
  const close = () => {
    document.body.classList.remove(styles.bodyOverflow)
    handleMaskScroll(false)
    setVisible(false);
  }
  const renderChildren = () => {
    if (typeof props.children === 'function') {
      return props.children(close)
    }
    return props.children
  }
  const renderFooter = () => {
    if (typeof props.footer === 'function') {
      return props.footer(close)
    }
    return props.footer
  }

  useIsomorphicLayoutEffect(() => {
    router?.beforePopState?.(() => {
      close()
      return true
    })
    return () => {
      close()
    }
  }, [])

  return (
    <AntModal
      visible={visible}
      {...omit(props, [
        'children', 'width', 'inPath', 'visible', 'footer',
        'onOk', 'onCancel', 'wrapClassName', 'closeIcon', 'destroyOnClose', 'router', 'onClose'
      ])}
      centered
      width={props.width || 640}
      footer={renderFooter()}
      onCancel={props?.onCancel?.(close)}
      closeIcon={props.closeIcon || <span className={styles.closeWrapper}>
        <Icon name="modalClose" className={cn(styles.closeIcon, {
          [styles.darkCloseIcon]: theme === THEME.DARK
        })} onClick={onClose?.(close) ?? close} data-buried={props.dataBuried}/>
      </span>}
      onOk={props?.onOk?.(close)}
      destroyOnClose={props.destroyOnClose ?? true}
      cancelButtonProps={{
        type: 'ghost',
        className: cn('kiki-button', styles.modalBtn, {
          [styles.darkCancelBtn]: theme === THEME.DARK
        }),
        ...props.cancelButtonProps
      }}
      okButtonProps={{
        type: 'primary',
        className: cn('kiki-button', styles.modalBtn, {
          [styles.darkOkBtn]: theme === THEME.DARK
        }),
        ...props.okButtonProps
      }}
      wrapClassName={cn(styles.modal, props.wrapClassName, {
        [styles.modalFooter]: props.footer,
        [styles.darkModal]: theme === THEME.DARK
      })}
    >
      {renderChildren()}
    </AntModal>
  )
}

export const openModal = (props: Omit<IModalProps, 'inPath'>) => {
  const { router } = props;
  const el = document.createElement('div');
  document.body.classList.add(styles.bodyOverflow);
  handleMaskScroll(true)
  reactDom.render(<Modal {...props} inPath={router?.pathname} />, el);
}
