import React, { Fragment } from 'react';
import reactDom from 'react-dom';

import { handleMaskScroll } from '@utils/index';

import styles from './index.module.less';

export interface ModalBaseProps {
  reject: <T = unknown>(reason?: T) => void;
  resolve: <P = unknown>(value?: P) => void;
  removePreview: () => void;
  children: (params?: Omit<ModalBaseProps, 'children'>) => React.ReactNode;
}

const Modal: React.FC<ModalBaseProps> = (props) => {
  const { reject, resolve, removePreview, children } = props;
  return (
    <Fragment>
      {children({ reject, resolve, removePreview })}
    </Fragment>
  )
}

export interface IOpenBaseModalParams extends Pick<ModalBaseProps, 'children'> {
  el: HTMLElement;
}

export const openBaseModal = ({ el: wrapper, children }: IOpenBaseModalParams) => {
  let el = document.createElement('div');
  wrapper.append(el);
  document.body.classList.add(styles.bodyOverflow);
  handleMaskScroll(true);
  const removePreview = () => {
    if (!el) return
    wrapper.removeChild(el)
    el = undefined;
    document.body.classList.remove(styles.bodyOverflow)
    handleMaskScroll(false)
  }
  return new Promise((resolve, reject) => {
    reactDom.render(<Modal resolve={resolve} reject={reject} removePreview={removePreview}>{children}</Modal>, el)
  })
}
