import React from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
// import { DatePickerProps } from 'antd/lib/date-picker';
import { RangePicker } from './RangePicker';

// export interface IProps extends DatePickerProps { }

function DatePicker (props) {
  return (
    <AntdDatePicker
      {...props}
    />
  )
}

export {
  DatePicker,
  RangePicker
}
