import React from 'react'
import I18n from '@utils/i18n';
import { getLoginPath } from '@utils/index';
import styles from './index.module.css'
import { useRouter } from 'next/router';
import { LOGIN_BEFORE_PATHS } from '@constants/index';

export const NoLogin = () => {
  const router = useRouter()
  return (
    <div className={styles.noLoginTip}>
      <span onClick={() => router.push(getLoginPath())}>{I18n.t('login')} </span>
      <span>{I18n.t('3B8ZC3SQ')}</span>
      <span onClick={() => router.push(LOGIN_BEFORE_PATHS.REGISTER)}> {I18n.t('Sign.up.now')}</span>
      <span>{I18n.t('raaLtXwa')}</span>
  </div>
  );
}
