import React, { memo, useEffect, useRef } from 'react';

import cn from 'classnames'
import lottie from 'lottie-web';

import styles from './index.module.css'

type LoadingPropsTypes= {
  className?: string;
  path: string
}
// eslint-disable-next-line react/display-name
const T:React.FC<LoadingPropsTypes> = (props) => {
  const { className, path } = props
  const lottieRef = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: lottieRef.current,
      renderer: 'svg',
      loop: true,
      // path: 'https://assets2.lottiefiles.com/packages/lf20_ctwkstoj.json'
      path: path
    })
  }, []);
  return (
        <div className={cn(styles.main)}>
            <div ref={lottieRef} className={cn(styles.content, className)}></div>
        </div>
  )
}

export const Loading = memo(T)
