import React from 'react';

import { message } from 'antd';
import cn from 'classnames';

import Icon from '@components/Icon';
import { copyText } from '@utils/clipboard'
import I18n from '@utils/i18n';

import styles from './index.module.css';

// export interface ICopyBtnProps {
//   locale: String;
//   copyText: String;
//   className?: String;
//   IconName?: String;
// }

export function CopyBtn (props) {
  const { className, iconClassName, text, IconName = 'tableCopy', ...rest } = props;

  return (
    <span
      {...rest}
      className={cn(styles.copyIcon, className)}
      onClick={(e) => {
        copyText(text)
        message.success(I18n.t('copySuccess'), 2)
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Icon name={IconName} className={iconClassName} />
    </span>

  )
}
