import React from 'react';
import { BackTop as AntdBackTop } from 'antd';
import cn from 'classnames';
import Icon from '@components/Icon';
// import { BackTopProps } from 'antd/lib/back-top';

// export interface IBackTopProps extends BackTopProps { }

export function BackTop (props) {
  const { className } = props;
  return (
    <AntdBackTop>
      <div className={cn('kiki-back-top', className)}>
        <Icon name='backTop' className='back-top-icon' data-buried='back-top' />
      </div>
    </AntdBackTop>
  )
}
