import { SupportAPI } from '@api/support';
import {
  AIRDROP_PATHS,
  FINANCIAL,
  INVITATION,
  MARKET_PATHS,
  THEME,
  USER_PATHS
} from '@constants/index';
import { store } from '@store/index';
import helper from '@utils/helper';

const MARKET_MENU = [
  {
    iconName: 'marketStation',
    name: 'quotes',
    href: MARKET_PATHS.MARKET,
    hasNextIcon: true,
    dataBuried: 'web_market_submenutype_select_markets',
    tips: '8ty873AO'
  },
  {
    iconName: 'findIcon',
    name: 'nEMVdvEh',
    href: MARKET_PATHS.FIND,
    hasNextIcon: true,
    dataBuried: 'web_market_submenutype_select_discover',
    tips: 'pkDbjWBm'
  }
];

const TRANSACTION_MENU = [
  // {
  //   iconName: 'trade',
  //   name: 'market_spot',
  //   href: `${TRADE_PATHS.TRADE}/BTC_USDT`,
  //   hasNextIcon: true
  // },
  // {
  //   iconName: 'regularInvestment',
  //   name: 'regularInvestment',
  //   href: FINANCIAL.AIP,
  //   hasNextIcon: true
  // }
]

const FINACIAL_MENU = [
  // {
  //   iconName: 'flexRegular',
  //   name: 'flexibleAndFixed',
  //   href: FINANCIAL.REGULAR_AND_FLEXIBLE,
  //   hasNextIcon: true
  // },
  {
    iconName: 'regularInvestment',
    name: 'regularInvestment',
    href: FINANCIAL.AIP,
    hasNextIcon: true
  }
];

const ACTIVITY_MENU = [
  {
    iconName: 'invite_friends',
    name: 'hKcw74Rd',
    href: INVITATION,
    hasNextIcon: true
  },
  {
    iconName: 'airdropPlan',
    name: 'Z9gqKqVO',
    href: AIRDROP_PATHS.ME,
    hasNextIcon: true
  }
]

const web3ProfileMenu = [
  {
    // 其他设置
    activekey: '3',
    iconName: 'othersettings',
    name: 'aKnW5aI8',
    href: USER_PATHS.OTHER_SETTING
  },
  {
    // 联系客服
    activekey: '7',
    iconName: 'contactService',
    name: 'user_contact_us',
    customerLink: 'https://kikitrade.zendesk.com/hc/en-hk/requests/new'
  }
]
export function getProfileMenu () {
  return web3ProfileMenu;
}
export const jumpPageMap = {
  7: () => {
    // 进行区分判断当前系统应该使用中文客服（现有）和英文客服表单（新增）
    if (helper.isNormalContactUs(store.userStore.socialUserInfo.countryCode)) {
      SupportAPI.getNamedSupportUrl()
        .then((contactCustomerUrl) => {
          window.open(contactCustomerUrl);
        })
        .catch((e) => {
          console.log('error:', e);
        });
    } else {
      window.open('https://kikitrade.zendesk.com/hc/en-hk/requests/new');
    }
  }
};

const BACKGROUND_IMG = {
  [THEME.HIGHLIGHT]: require('@images/logo_white_bg.webp'),
  [THEME.DARK]: require('@images/logo_dark_bg.webp')
};

const SEARCH_IMG = {
  [THEME.HIGHLIGHT]: require('@images/search.webp'),
  [THEME.DARK]: require('@images/darkSearch.webp')
};

const MENU_IMG = {
  [THEME.HIGHLIGHT]: require('@images/icon/menu.webp'),
  [THEME.DARK]: require('@images/darkMenu.webp')
};

export {
  ACTIVITY_MENU,
  BACKGROUND_IMG,
  FINACIAL_MENU,
  MARKET_MENU,
  MENU_IMG,
  SEARCH_IMG,
  TRANSACTION_MENU
};
