import React from 'react';

import { Slider as AntSlider } from 'antd';
import cn from 'classnames';

export const Slider = (props) => {
  return (
    <AntSlider {...props} className={cn('kiki-slider', props.className)}/>
  )
}
