import React from 'react';
import { useFavorite } from './useFavorite';
import cn from 'classnames';
import Icon from '@components/Icon';
import { inject, observer } from 'mobx-react';
import styles from './index.module.less';

const T = ({ className, userStore: { isLogin }, ...props }) => {
  const { favoriteChange, isFavorite } = useFavorite({ ...props, isLogin });
  return (
    <span onClick={favoriteChange}>
      <Icon
        name="star"
        className={cn(className, styles.start, {
          [styles.startActive]: isFavorite && isLogin
        })}
      />
    </span>
  );
};

export const Favorite = inject((rootStore) => ({
  userStore: rootStore.store.userStore,
  tradeStore: rootStore.store.tradeStore
}))(observer(T));
