import React from 'react';
import cn from 'classnames';
import I18n from '@utils/i18n';
import { Switch } from '@element/index';
import styles from './index.module.css';

export const MarginOption = (props) => {
  const { text = I18n.t('marginModeClosed'), className, help, checked, onChange, disabled } = props;
  return (
    <div className={cn(styles.marginMode, className)}>
      {text}
      {help}
      <Switch className={styles.switch} onChange={onChange} checked={checked} disabled={disabled} />
    </div>
  )
}
