import React, { useMemo, useState } from 'react';
import { Popover as AntdPopover } from 'antd';
// import { PopoverProps } from 'antd/lib/popover';
import cn from 'classnames';
import { Button } from '@components/Element';
import I18n from '@utils/i18n';
import useClient from '@lib/hooks/useClient';

// export interface IPopoverProps extends PopoverProps {
//     style?: any;
//     overlayClassName?: String;
//     hasArrow?: Boolean;
// }

export const Popover = (props) => {
  const {
    overlayClassName,
    hasArrow = false,
    mode = '',
    contenTitle,
    content,
    buttonType = null, // '1' '2'
    onOkCancel = I18n.t('common_cancel'),
    onOkText = I18n.t('common_confirm'),
    onThreeButtonText = I18n.t('common_cancel'),
    onCancel = () => {},
    onOk = () => {},
    onThreeClick = () => {}
  } = props;
  const isPc = useClient()
  const [visible, setVisible] = useState(false)
  const myOverlayClassName = cn(overlayClassName,
    {
      'no-arrow': !hasArrow,
      'kiki-popover': !mode,
      'kiki-popover-mode': mode === 'modal'
    }
  )

  const contentRender = () => {
    if (mode) {
      return (
        <div className='kiki-popover-main'>
          <div className='kiki-popover-mode-header Medium'>{contenTitle}</div>
          <div className='kiki-popover-mode-content'>{typeof content === 'function' ? content() : content}</div>
          {
            buttonType === '2' && <div className='kiki-popover-mode-footer'>
              <Button type='ghost' onClick={onCancel}><span className='Medium'>{onOkCancel}</span></Button>
              <Button type='ghost' onClick={onOk}><span className='Medium'>{onOkText}</span></Button>
          </div>
          }
          {
            buttonType === '3' && <div className='kiki-popover-mode-footer kiki-popover-mode-footer-three'>
              <Button type='ghost' onClick={onOk}><span className='Medium'>{onOkText}</span></Button>
              <Button type='ghost' onClick={onCancel}><span className='Medium'>{onOkCancel}</span></Button>
              <Button type='ghost' onClick={onThreeClick}><span className='Medium'>{onThreeButtonText}</span></Button>
          </div>
          }
        </div>
      )
    }
    return typeof content === 'function' ? content() : content
  }

  const popoverProps = useMemo(() => {
    if (isPc) {
      return {
        ...props,
        overlayClassName: myOverlayClassName,
        content: contentRender
      }
    }
    return {
      visible,
      onVisibleChange: (visible) => {
        setVisible(visible)
      },
      ...props,
      overlayClassName: myOverlayClassName,
      content: contentRender
    }
  }, [isPc, visible, props])

  return (
    <AntdPopover
      {...popoverProps}
      />
  )
}
