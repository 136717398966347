import React from 'react';
import cn from 'classnames';
import { Tooltip as AntdTooltip } from 'antd';

export const Tooltip = (props) => {
  const {
    className,
    color = '#fff',
    align = { offset: [0, 5] },
    placement = 'top',
    ...rest
  } = props;
  return (
      <AntdTooltip
        overlayClassName={cn('kiki-tooltip', className)}
        color={color}
        align={align}
        placement={placement}
        {...rest}
      />
  )
}
