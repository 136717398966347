import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { Modal } from 'antd';
import Image from 'next/legacy/image'
import Script from 'next/script';

import { Loading } from '@components/Element';
import { THEME } from '@constants/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks'

import I18n from '../../utils/i18n';

import styles from './index.module.css'
let grecaptchaId = null
let readyInterval;
const env = process.env.NEXT_PUBLIC_API_ENV;
const prodSiteKeys = ['pre', 'pre1', 'pro', 'prod'];

const siteKey = prodSiteKeys.includes(env) ? (
  process.env.NEXT_PUBLIC_GRECAPTCHA_SITEKEY_PROD
) : process.env.NEXT_PUBLIC_GRECAPTCHA_SITEKEY_BETA;

export default React.memo(function NoCaptchaModal (props) {
  const { modalVisible, closeModal, onFinish, destroyOnClose = false } = props
  const [jsSrc, setJsSrc] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const afterClose = useCallback(() => {
    closeHandler()
  }, [])
  const onExpire = useCallback(() => {
    onReset()
  }, [])
  const onReset = useCallback(() => {
    try {
      if (grecaptchaId !== null) {
        window.grecaptcha.reset()
        grecaptchaId = null
      }
    } catch (e) {

    }
  }, [])

  const onError = useCallback(() => {
    onReset()
  }, [])
  const onCancel = useCallback(() => {
    closeHandler()
  }, [])

  const onVerify = useCallback((token) => {
    console.log(token)

    const weviewData = {
      verificationType: 1,
      sig: '',
      token: token,
      scene: 'v2',
      sessionId: ''

    }
    onFinish(weviewData)
    closeHandler()
  }, [onFinish])
  const closeHandler = useCallback(() => {
    console.log('grecaptchaId', grecaptchaId)
    onReset()

    closeModal()
  }, [closeModal])

  const getJsSrc = useCallback(() => {
    let language = 'en'
    if (I18n.locale === 'zh-TC') {
      language = 'zh-TW'
    } else if (I18n.locale === 'zh-CN') {
      language = 'zh-CN'
    }
    const jsSrc = `https://www.google.com/recaptcha/api.js?hl=${language}`
    setJsSrc(jsSrc)
    console.log('jsSrc', jsSrc)
  }, [])

  const renderRecaptcha = useCallback(() => {
    const theme = THEME.HIGHLIGHT;
    const size = 'normal';

    if (window.grecaptcha && window.grecaptcha.render) {
      try {
        grecaptchaId = window.grecaptcha.render('recaptcha-container', {
          sitekey: siteKey,
          size,
          theme,
          callback: onVerify,
          'expired-callback': onExpire,
          'error-callback': onError
        });
      } catch (e) {

      }
    }
  }, [onVerify])

  useIsomorphicLayoutEffect(() => {
    if (modalVisible) {
      getJsSrc()
      const isReady = () => !!(
        typeof window === 'object' &&
        window.grecaptcha &&
        window.grecaptcha.render
      );
      const updateReadyState = () => {
        console.log('isReady', isReady())
        if (isReady()) {
          clearInterval(readyInterval);
          renderRecaptcha()
          setIsLoaded(true)
        }
      }

      if (isReady()) {
        renderRecaptcha();
      } else {
        readyInterval = setInterval(updateReadyState, 1000);
      }
    }
  }, [modalVisible])

  return (
    <Modal
      onCancel={onCancel}
      afterClose={afterClose}
      title={I18n.t('nocaptch_modal_title')}
      visible={modalVisible}
      // footer={null}
      centered
      destroyOnClose={destroyOnClose}
      closeIcon={<Image
        alt=''
        src={require('../../images/icon/close1.webp')}
        layout="fixed"
        width={(28)}
        className={'ant-modal-title-close-icon'}
        height={(28)}
      />}
      onOk={onFinish}
    >
      <View
        style={{
          borderRadius: 12
        }}
      >

        <div className={'ant-modal-main'}>
          {
            !isLoaded
              ? <div className={styles.loading} >
                <Loading path='/animations/loading_circle.json' />
              </div>
              : null
          }
          <style >{`
                html,
                body,
                .container {
                    height: 100%;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    background-color: transparent;
                }
        
                .container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 300px
                }
                .grecaptcha-badge { 
                    visibility: hidden; 
                }


            `}</style>
          <div className="container">
            <span id="recaptcha-container"></span>
          </div>
        </div>
        {
          jsSrc
            ? <Script
              id="stripe-js"
              src={jsSrc}
            />
            : null

        }

      </View>
    </Modal>
  );
})
